@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";

.App {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.bp3-card {
  margin-bottom: 1em;
}

.bp3-non-ideal-state > div {
  max-width: none;
}

.card-header h1 {
  margin: 0;
}

.card-header a {
  text-decoration: none;
}

.card-header, .card-footer {
  text-align: center;
}
